<template functional>
	<div class="block ce-frame"
		:class="[
			`frame-${props.frameClass}`,
			`layout-${props.layout}`,
			`space-before-${props.spaceBefore}`,
			`space-after-${props.spaceAfter}`
		]"
	>
		<slot />
	</div>
  </template>

<script>
export default {
    name: 'CeFrame'
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/common';
.frame-default {
	@extend .container-fluid !optional;
	padding-bottom: 5.5rem;

	@include media-breakpoint-down(sm) {
		padding-bottom: 3rem;
	}
}

.frame-indent {
    @extend .container !optional;
}

.space {
    &-before {
        &-extra-small {
            padding-top: 1.5rem !important;
        }

        &-small {
            padding-top: 3rem !important;
        }

        &-medium {
            padding-top: 3.5rem !important;
        }

        &-large {
			@include media-breakpoint-up(md) {
				padding-top: 5.5rem !important;
			}
        }

        &-extra-large {
			@include media-breakpoint-up(md) {
				padding-top: 6.5rem !important;
			}
        }
    }

    &-after {
        &-extra-small {
            padding-bottom: 1.5rem !important;
        }

        &-small {
            padding-bottom: 3rem !important;
        }

        &-medium {
           padding-bottom: 3.5rem !important;
        }

        &-large {
			@include media-breakpoint-up(md) {
				padding-bottom: 5.5rem !important;
			}
        }

        &-extra-large {
			@include media-breakpoint-up(md) {
				padding-bottom: 6.5rem !important;
			}
        }
    }
}
</style>
