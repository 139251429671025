<template>
	<div id="share-network-list">
		<ShareNetwork
			v-for="network in getSelectedNetworks"
			:network="network.network"
			:key="network.network"
			:style="{backgroundColor: network.color}"
			:url="sharing.url"
			:title="sharing.title"
			:description="sharing.description"
		>
			<div class="icon-wrapper">
				<font-awesome-icon
					:icon="network.icon"
				>
				</font-awesome-icon>
			</div>
			<span>{{ network.name }}</span>
		</ShareNetwork>
	</div>
</template>

<script>
import SocialShare from 'vue-social-sharing';
import { mapState } from 'vuex';

const AVAILABLE_NETWORKS = [
	{ network: 'baidu', name: 'Baidu', icon: ['fas', 'paw'], color: '#2529d8' },
	{ network: 'buffer', name: 'Buffer', icon: ['fab', 'buffer'], color: '#323b43' },
	{ network: 'email', name: 'Email', icon: ['far', 'envelope'], color: '#333333' },
	{ network: 'evernote', name: 'Evernote', icon: ['fab', 'evernote'], color: '#2dbe60' },
	{ network: 'facebook', name: 'Facebook', icon: ['fab', 'facebook-f'], color: '#1877f2' },
	{ network: 'flipboard', name: 'Flipboard', icon: ['fab', 'flipboard'], color: '#e12828' },
	{ network: 'hackernews', name: 'HackerNews', icon: ['fab', 'hacker-news'], color: '#ff4000' },
	{ network: 'instapaper', name: 'Instapaper', icon: ['fas', 'italic'], color: '#428bca' },
	{ network: 'line', name: 'Line', icon: ['fab', 'line'], color: '#00c300' },
	{ network: 'linkedin', name: 'LinkedIn', icon: ['fab', 'linkedin'], color: '#007bb5' },
	{ network: 'odnoklassniki', name: 'Odnoklassniki', icon: ['fab', 'odnoklassniki'], color: '#ed812b' },
	{ network: 'pinterest', name: 'Pinterest', icon: ['fab', 'pinterest'], color: '#bd081c' },
	{ network: 'pocket', name: 'Pocket', icon: ['fab', 'get-pocket'], color: '#ef4056' },
	{ network: 'quora', name: 'Quora', icon: ['fab', 'quora'], color: '#a82400' },
	{ network: 'reddit', name: 'Reddit', icon: ['fab', 'reddit-alien'], color: '#ff4500' },
	{ network: 'skype', name: 'Skype', icon: ['fab', 'skype'], color: '#00aff0' },
	{ network: 'sms', name: 'SMS', icon: ['far', 'comment-dots'], color: '#333333' },
	{ network: 'stumbleupon', name: 'StumbleUpon', icon: ['fab', 'stumbleupon'], color: '#eb4924' },
	{ network: 'telegram', name: 'Telegram', icon: ['fab', 'telegram-plane'], color: '#0088cc' },
	{ network: 'tumblr', name: 'Tumblr', icon: ['fab', 'tumblr'], color: '#35465c' },
	{ network: 'twitter', name: 'Twitter', icon: ['fab', 'twitter'], color: '#1da1f2' },
	{ network: 'viber', name: 'Viber', icon: ['fab', 'viber'], color: '#59267c' },
	{ network: 'vk', name: 'Vk', icon: ['fab', 'vk'], color: '#4a76a8' },
	{ network: 'weibo', name: 'Weibo', icon: ['fab', 'weibo'], color: '#e9152d' },
	{ network: 'whatsapp', name: 'Whatsapp', icon: ['fab', 'whatsapp'], color: '#25d366' },
	{ network: 'wordpress', name: 'Wordpress', icon: ['fab', 'wordpress'], color: '#21759b' },
	{ network: 'xing', name: 'Xing', icon: ['fab', 'xing'], color: '#026466' },
	{ network: 'yammer', name: 'Yammer', icon: ['fab', 'yammer'], color: '#0072c6' },
];

export default {
	name: 'SocialShare',
	components: {
		SocialShare
	},
	props: {
		networks: {
			type: Array
		}
	},
	data() {
		return {
			sharing: {
				url: '',
				title: '',
				description: '',
			},
		}
	},
	mounted() {
		this.sharing.url = window.location.href;
		this.sharing.title = this.storeTitle;
		this.sharing.description = this.storeDescription;
	},
	computed: {
		...mapState({
			storeTitle: (state) => {
				return state.typo3.page.page.meta.title
					|| state.typo3.page.page.title
					|| ''
				;
			},
			storeDescription: (state) => {
				return state.typo3.page.page.meta.description
					|| state.typo3.page.page.meta.abstract
					|| ''
				;
			},
		}),
		getSelectedNetworks() {
			return AVAILABLE_NETWORKS.filter((n) => {
				return this.networks[0].includes(n.network);
			})
		},
	},
};
</script>

<style lang="scss">
	@import '~@/assets/scss/common';
	$share-network--item-list-margin: 10px !default;
	$share-network--item-background: $isd--blue-dark;
	$share-network--item-icon-wrapper-background: rgba(0, 0, 0, 0.2);
	$share-network--item-icon-font-size: rem(20);

	#share-network-list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: auto;
	}

	a[class^="share-network-"] {
		flex: none;
		color: $isd--white;
		background-color: $share-network--item-background;
		border-radius: 3px;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		align-content: center;
		align-items: center;
		cursor: pointer;
		margin: 0 $share-network--item-list-margin $share-network--item-list-margin 0;
		line-height: 0;

		&:hover {
			 color: $isd--white;
			 opacity: 0.8;
		}

		.icon-wrapper {
			background-color: $share-network--item-icon-wrapper-background;
			flex: 0 1 auto;
			padding: $share-network--item-list-margin;
			font-size: $share-network--item-icon-font-size;
		}

		span {
			padding: 0 $share-network--item-list-margin;
			flex: 1 1 0;
			font-weight: 500;
		}
	}
</style>
