<template>
	<div v-if="development" class="breakpoints shadow-sm rounded-pill">
		<span class="d-sm-none">XS</span>
		<span class="d-none d-sm-block d-md-none">SM</span>
		<span class="d-none d-md-block d-lg-none">MD</span>
		<span class="d-none d-lg-block d-xl-none">LG</span>
		<span class="d-none d-xl-block">XL</span>
	</div>
</template>

<script>
export default {
	data() {
		return {
			development: false,
		}
	},
	methods: {
		isDevelopment() {
			if (process.env.NUXT_HOST) {
				this.development = true
			} else {
				this.development = false
			}
		}
	},
	mounted() {
		this.isDevelopment();
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.breakpoints {
	background: rgba(255, 255, 255, .8);
	bottom: .2rem;
	color: #555;
	left: .2rem;
	position: fixed;
	z-index: $zindex-sticky;
	text-align: center;
	padding: rem(10);
	@include responsive-type(1, 0);
}
</style>
